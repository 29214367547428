<template>
  <div>
    <a-modal
      v-model:visible="modalVisibleContent"
      title="Detail Content"
      :footer="null"
      :width="1000"
      @ok="handleOkOrCancel"
      @cancel="handleOkOrCancel"
      destroy-on-close
    >
      <a-form layout="vertical">
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="Thumbnail">
              <img
                :src="`${URL}/${selectedContent.thumbnail}`"
                :alt="selectedContent.sentence"
                class="img-thumbnail"
              />
            </a-form-item>
            <a-form-item label="Username Creator">
              <a-input placeholder="Username" :value="selectedContent.creator.username" />
            </a-form-item>
            <a-form-item label="Price">
              <a-input placeholder="Price" :value="selectedContent.price" />
            </a-form-item>
            <a-form-item label="Sentence">
              <a-input placeholder="Sentence" :value="selectedContent.sentence" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Contents">
              <a-carousel
                dots-class="slick-dots slick-thumb"
                v-if="selectedContent.attachments.length"
              >
                <template #customPaging="props">
                  <a v-if="selectedContent.attachments[props.i].type === 'image'">
                    <img
                      :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                      :alt="selectedContent.attachments[props.i].caption"
                      style="height: 25px ;"
                    />
                    <!-- <img
                      :src="`${URL}/${selectedContent.thumbnail}`"
                      :alt="selectedContent.attachments[props.i].caption"
                      style="height: 25px ;"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    /> -->

                    <!-- <video-player
                      :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                      :poster="`${URL}/${selectedContent.thumbnail}`"
                      controls
                      :volume="0.6"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    /> -->
                    <!-- <vue-core-video-player
                      :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                      :controls="true"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    ></vue-core-video-player> -->
                  </a>
                  <a v-else-if="selectedContent.attachments[props.i].type === 'video'">
                    <video style="height: 25px;">
                      <source
                        :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                        type="video/mp4"
                      />
                    </video>
                  </a>
                </template>
                <div v-for="item in selectedContent.attachments" :key="item.id">
                  <div
                    style="display: flex;flex-direction: column; justify-content: center; align-items: center;"
                  >
                    <a-image
                      :src="`${URL}/${item.url}`"
                      :alt="item.caption"
                      style="height: 150px; width: 250px;"
                      v-if="item.type === 'image'"
                    />
                    <video
                      v-else-if="item.type === 'video'"
                      style="height: 150px; width: 250px;"
                      controls
                    >
                      <source :src="`${URL}/${item.url}`" type="video/mp4" />
                    </video>
                    <p style="text-align: center;margin-bottom: 0">{{ item.caption }}</p>
                  </div>
                </div>
              </a-carousel>
              <div v-else>Empty Content</div>
            </a-form-item>
            <a-form-item label="Comments">
              <div v-if="selectedContent.lastComments.length">
                <div v-for="(item, index) in selectedContent.lastComments" :key="index">
                  <div style="margin-bottom: 15px;">
                    <div style="display: flex; align-items: center; margin-bottom: 5px;">
                      <a-avatar
                        :src="`${URL}/${item.creator.avatar_sm}`"
                        v-if="item.creator.avatar_sm"
                      />
                      <a-avatar
                        :src="`${URL}/${item.creator.avatar}`"
                        v-else-if="item.creator.avatar"
                      />
                      <a-avatar v-else>
                        <template #icon><UserOutlined /></template
                      ></a-avatar>
                      <p
                        style="margin-left: 5px; font-weight: 700; text-align: center;margin-bottom: 0;"
                      >
                        {{ item.creator.username }}
                      </p>
                    </div>
                    <p style="margin-bottom: 0;">
                      {{ item.comment }}
                    </p>
                    <div
                      style="background-color: black; width: 100%; height: 1px;margin-top: 5px;"
                    ></div>
                  </div>
                </div>
              </div>
              <div v-else>
                Empty Comment
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="modalVisibleStory"
      title="Detail Story"
      :footer="null"
      :width="1000"
      @ok="handleOkOrCancel"
      @cancel="handleOkOrCancel"
      destroy-on-close
    >
      <a-form layout="vertical">
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="Username Creator">
              <a-input placeholder="Username" :value="selectedStory.creator.username" />
            </a-form-item>
            <a-form-item label="Restory Content">
              <a-carousel dots-class="slick-dots slick-thumb" v-if="selectedStory.restoryContent">
                <template #customPaging="props">
                  <a v-if="selectedStory.restoryContent.attachments[props.i].type === 'image'">
                    <img
                      :src="`${URL}/${selectedStory.restoryContent.attachments[props.i].url}`"
                      :alt="selectedStory.restoryContent.attachments[props.i].caption"
                      style="height: 25px ;"
                    />
                    <!-- <img
                      :src="`${URL}/${selectedContent.thumbnail}`"
                      :alt="selectedContent.attachments[props.i].caption"
                      style="height: 25px ;"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    /> -->

                    <!-- <video-player
                      :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                      :poster="`${URL}/${selectedContent.thumbnail}`"
                      controls
                      :volume="0.6"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    /> -->
                    <!-- <vue-core-video-player
                      :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                      :controls="true"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    ></vue-core-video-player> -->
                  </a>
                  <a v-else-if="selectedStory.restoryContent.attachments[props.i].type === 'video'">
                    <video style="height: 25px;">
                      <source
                        :src="`${URL}/${selectedStory.restoryContent.attachments[props.i].url}`"
                        type="video/mp4"
                      />
                    </video>
                  </a>
                </template>
                <div v-for="item in selectedStory.restoryContent.attachments" :key="item.id">
                  <div
                    style="display: flex;flex-direction: column; justify-content: center; align-items: center;"
                  >
                    <a-image
                      :src="`${URL}/${item.url}`"
                      :alt="item.caption"
                      style="height: 150px; width: 250px;"
                      v-if="item.type === 'image'"
                    />
                    <video
                      v-else-if="item.type === 'video'"
                      style="height: 150px; width: 250px;"
                      controls
                    >
                      <source :src="`${URL}/${item.url}`" type="video/mp4" />
                    </video>
                    <p style="text-align: center;margin-bottom: 0">{{ item.caption }}</p>
                  </div>
                </div>
              </a-carousel>
              <!-- <a-carousel
                arrows
                dots-class="slick-dots slick-thumb"
                v-if="selectedStory.restoryContent"
              >
                <template #customPaging="props">
                  <a>
                    <img
                      :src="`${URL}/${selectedStory.restoryContent.attachments[props.i].url}`"
                      :alt="selectedStory.restoryContent.attachments[props.i].caption"
                      style="height: 25px ;"
                    />
                  </a>
                </template>
                <div v-for="item in selectedStory.restoryContent.attachments" :key="item.id">
                  <div style="display: flex;flex-direction: column;">
                    <a-image
                      :src="`${URL}/${item.url}`"
                      :alt="item.caption"
                      style="height: 200px ;"
                    />
                    <p style="text-align: center;margin-bottom: 0;">{{ item.caption }}</p>
                  </div>
                </div>
              </a-carousel> -->
              <div v-else>Empty Restory Content</div>
            </a-form-item>
            <a-form-item label="Restory Story">
              <a-carousel dots-class="slick-dots slick-thumb" v-if="selectedStory.restoryStory">
                <template #customPaging="props">
                  <a v-if="selectedStory.restoryStory.attachments[props.i].type === 'image'">
                    <img
                      :src="`${URL}/${selectedStory.restoryStory.attachments[props.i].url}`"
                      :alt="selectedStory.restoryStory.attachments[props.i].caption"
                      style="height: 25px ;"
                    />
                    <!-- <img
                      :src="`${URL}/${selectedContent.thumbnail}`"
                      :alt="selectedContent.attachments[props.i].caption"
                      style="height: 25px ;"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    /> -->

                    <!-- <video-player
                      :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                      :poster="`${URL}/${selectedContent.thumbnail}`"
                      controls
                      :volume="0.6"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    /> -->
                    <!-- <vue-core-video-player
                      :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                      :controls="true"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    ></vue-core-video-player> -->
                  </a>
                  <a v-else-if="selectedStory.restoryStory.attachments[props.i].type === 'video'">
                    <video style="height: 25px;">
                      <source
                        :src="`${URL}/${selectedStory.restoryStory.attachments[props.i].url}`"
                        type="video/mp4"
                      />
                    </video>
                  </a>
                </template>
                <div v-for="item in selectedStory.restoryStory.attachments" :key="item.id">
                  <div
                    style="display: flex;flex-direction: column; justify-content: center; align-items: center;"
                  >
                    <a-image
                      :src="`${URL}/${item.url}`"
                      :alt="item.caption"
                      style="height: 150px; width: 250px;"
                      v-if="item.type === 'image'"
                    />
                    <video
                      v-else-if="item.type === 'video'"
                      style="height: 150px; width: 250px;"
                      controls
                    >
                      <source :src="`${URL}/${item.url}`" type="video/mp4" />
                    </video>
                    <p style="text-align: center;margin-bottom: 0">{{ item.caption }}</p>
                  </div>
                </div>
              </a-carousel>
              <!-- <a-carousel
                arrows
                dots-class="slick-dots slick-thumb"
                v-if="selectedStory.restoryStory"
              >
                <template #customPaging="props">
                  <a>
                    <img
                      :src="`${URL}/${selectedStory.restoryStory.attachments[props.i].url}`"
                      :alt="selectedStory.restoryStory.attachments[props.i].caption"
                      style="height: 25px ;"
                    />
                  </a>
                </template>
                <div v-for="item in selectedStory.restoryStory.attachments" :key="item.id">
                  <div style="display: flex;flex-direction: column;">
                    <a-image
                      :src="`${URL}/${item.url}`"
                      :alt="item.caption"
                      style="height: 200px ;"
                    />
                    <p style="text-align: center;margin-bottom: 0;">{{ item.caption }}</p>
                  </div>
                </div>
              </a-carousel> -->
              <div v-else>Empty Restory Story</div>
            </a-form-item>
            <a-form-item label="Sentence">
              <a-input placeholder="Sentence" :value="selectedStory.sentence" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Story">
              <a-carousel
                dots-class="slick-dots slick-thumb"
                v-if="selectedStory.attachments.length"
              >
                <template #customPaging="props">
                  <a v-if="selectedStory.attachments[props.i].type === 'image'">
                    <img
                      :src="`${URL}/${selectedStory.attachments[props.i].url}`"
                      :alt="selectedStory.attachments[props.i].caption"
                      style="height: 25px ;"
                    />
                    <!-- <img
                      :src="`${URL}/${selectedContent.thumbnail}`"
                      :alt="selectedContent.attachments[props.i].caption"
                      style="height: 25px ;"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    /> -->

                    <!-- <video-player
                      :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                      :poster="`${URL}/${selectedContent.thumbnail}`"
                      controls
                      :volume="0.6"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    /> -->
                    <!-- <vue-core-video-player
                      :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                      :controls="true"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    ></vue-core-video-player> -->
                  </a>
                  <a v-else-if="selectedStory.attachments[props.i].type === 'video'">
                    <video style="height: 25px;">
                      <source
                        :src="`${URL}/${selectedStory.attachments[props.i].url}`"
                        type="video/mp4"
                      />
                    </video>
                  </a>
                </template>
                <div v-for="item in selectedStory.attachments" :key="item.id">
                  <div
                    style="display: flex;flex-direction: column; justify-content: center; align-items: center;"
                  >
                    <a-image
                      :src="`${URL}/${item.url}`"
                      :alt="item.caption"
                      style="height: 150px; width: 250px;"
                      v-if="item.type === 'image'"
                    />
                    <video
                      v-else-if="item.type === 'video'"
                      style="height: 150px; width: 250px;"
                      controls
                    >
                      <source :src="`${URL}/${item.url}`" type="video/mp4" />
                    </video>
                    <p style="text-align: center;margin-bottom: 0">{{ item.caption }}</p>
                  </div>
                </div>
              </a-carousel>
              <!-- <a-carousel
                arrows
                dots-class="slick-dots slick-thumb"
                v-if="selectedStory.attachments.length"
              >
                <template #customPaging="props">
                  <a>
                    <img
                      :src="`${URL}/${selectedStory.attachments[props.i].url}`"
                      :alt="selectedStory.attachments[props.i].caption"
                      style="height: 25px ;"
                    />
                  </a>
                </template>
                <div v-for="item in selectedStory.attachments" :key="item.id">
                  <div style="display: flex;flex-direction: column;">
                    <a-image
                      :src="`${URL}/${item.url}`"
                      :alt="item.caption"
                      style="height: 200px ;"
                    />
                    <p style="text-align: center;margin-bottom: 0;">{{ item.caption }}</p>
                  </div>
                </div>
              </a-carousel> -->
              <div v-else>Empty Story</div>
            </a-form-item>
            <a-form-item label="Long Story">
              <div v-if="selectedStory.longstories">
                <div v-for="(item, index) in selectedStory.longstories" :key="index">
                  <a-card :title="`Story ${index + 1}`" style="width: 100%">
                    <a-form-item label="Restory Content">
                      <a-carousel dots-class="slick-dots slick-thumb" v-if="item.restoryContent">
                        <template #customPaging="props">
                          <a v-if="item.restoryContent.attachments[props.i].type === 'image'">
                            <img
                              :src="`${URL}/${item.restoryContent.attachments[props.i].url}`"
                              :alt="item.restoryContent.attachments[props.i].caption"
                              style="height: 25px ;"
                            />
                            <!-- <img
                      :src="`${URL}/${selectedContent.thumbnail}`"
                      :alt="selectedContent.attachments[props.i].caption"
                      style="height: 25px ;"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    /> -->

                            <!-- <video-player
                      :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                      :poster="`${URL}/${selectedContent.thumbnail}`"
                      controls
                      :volume="0.6"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    /> -->
                            <!-- <vue-core-video-player
                      :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                      :controls="true"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    ></vue-core-video-player> -->
                          </a>
                          <a v-else-if="item.restoryContent.attachments[props.i].type === 'video'">
                            <video style="height: 25px;">
                              <source
                                :src="`${URL}/${item.restoryContent.attachments[props.i].url}`"
                                type="video/mp4"
                              />
                            </video>
                          </a>
                        </template>
                        <div
                          v-for="itemContent in item.restoryContent.attachments"
                          :key="itemContent.id"
                        >
                          <div
                            style="display: flex;flex-direction: column; justify-content: center; align-items: center;"
                          >
                            <a-image
                              :src="`${URL}/${itemContent.url}`"
                              :alt="itemContent.caption"
                              style="height: 150px; width: 250px;"
                              v-if="itemContent.type === 'image'"
                            />
                            <video
                              v-else-if="itemContent.type === 'video'"
                              style="height: 150px; width: 250px;"
                              controls
                            >
                              <source :src="`${URL}/${itemContent.url}`" type="video/mp4" />
                            </video>
                            <p style="text-align: center;margin-bottom: 0">
                              {{ itemContent.caption }}
                            </p>
                          </div>
                        </div>
                      </a-carousel>
                      <!-- <a-carousel
                        arrows
                        dots-class="slick-dots slick-thumb"
                        v-if="item.restoryContent"
                      >
                        <template #customPaging="props">
                          <a>
                            <img
                              :src="`${URL}/${item.restoryContent.attachments[props.i].url}`"
                              :alt="item.restoryContent.attachments[props.i].caption"
                              style="height: 25px ;"
                            />
                          </a>
                        </template>
                        <div
                          v-for="itemContent in item.restoryContent.attachments"
                          :key="itemContent.id"
                        >
                          <div style="display: flex;flex-direction: column;">
                            <a-image
                              :src="`${URL}/${itemContent.url}`"
                              :alt="itemContent.caption"
                              style="height: 200px ;"
                            />
                            <p style="text-align: center;margin-bottom: 0;">
                              {{ itemContent.caption }}
                            </p>
                          </div>
                        </div>
                      </a-carousel> -->
                      <div v-else>Empty Restory Content</div>
                    </a-form-item>
                    <a-form-item label="Restory Story">
                      <a-carousel dots-class="slick-dots slick-thumb" v-if="item.restoryStory">
                        <template #customPaging="props">
                          <a v-if="item.restoryStory.attachments[props.i].type === 'image'">
                            <img
                              :src="`${URL}/${item.restoryStory.attachments[props.i].url}`"
                              :alt="item.restoryStory.attachments[props.i].caption"
                              style="height: 25px ;"
                            />
                            <!-- <img
                      :src="`${URL}/${selectedContent.thumbnail}`"
                      :alt="selectedContent.attachments[props.i].caption"
                      style="height: 25px ;"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    /> -->

                            <!-- <video-player
                      :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                      :poster="`${URL}/${selectedContent.thumbnail}`"
                      controls
                      :volume="0.6"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    /> -->
                            <!-- <vue-core-video-player
                      :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                      :controls="true"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    ></vue-core-video-player> -->
                          </a>
                          <a v-else-if="item.restoryStory.attachments[props.i].type === 'video'">
                            <video style="height: 25px;">
                              <source
                                :src="`${URL}/${item.restoryStory.attachments[props.i].url}`"
                                type="video/mp4"
                              />
                            </video>
                          </a>
                        </template>
                        <div v-for="itemStory in item.restoryStory.attachments" :key="itemStory.id">
                          <div
                            style="display: flex;flex-direction: column; justify-content: center; align-items: center;"
                          >
                            <a-image
                              :src="`${URL}/${itemStory.url}`"
                              :alt="itemStory.caption"
                              style="height: 150px; width: 250px;"
                              v-if="itemStory.type === 'image'"
                            />
                            <video
                              v-else-if="itemStory.type === 'video'"
                              style="height: 150px; width: 250px;"
                              controls
                            >
                              <source :src="`${URL}/${itemStory.url}`" type="video/mp4" />
                            </video>
                            <p style="text-align: center;margin-bottom: 0">
                              {{ itemStory.caption }}
                            </p>
                          </div>
                        </div>
                      </a-carousel>
                      <!-- <a-carousel
                        arrows
                        dots-class="slick-dots slick-thumb"
                        v-if="item.restoryStory"
                      >
                        <template #customPaging="props">
                          <a>
                            <img
                              :src="`${URL}/${item.restoryStory.attachments[props.i].url}`"
                              :alt="item.restoryStory.attachments[props.i].caption"
                              style="height: 25px ;"
                            />
                          </a>
                        </template>
                        <div v-for="itemStory in item.restoryStory.attachments" :key="itemStory.id">
                          <div style="display: flex;flex-direction: column;">
                            <a-image
                              :src="`${URL}/${itemStory.url}`"
                              :alt="itemStory.caption"
                              style="height: 200px ;"
                            />
                            <p style="text-align: center;margin-bottom: 0;">
                              {{ itemStory.caption }}
                            </p>
                          </div>
                        </div>
                      </a-carousel> -->
                      <div v-else>Empty Restory Story</div>
                    </a-form-item>
                    <a-form-item label="Contents">
                      <a-carousel
                        dots-class="slick-dots slick-thumb"
                        v-if="item.attachments.length"
                      >
                        <template #customPaging="props">
                          <a v-if="item.attachments[props.i].type === 'image'">
                            <img
                              :src="`${URL}/${item.attachments[props.i].url}`"
                              :alt="item.attachments[props.i].caption"
                              style="height: 25px ;"
                            />
                            <!-- <img
                      :src="`${URL}/${selectedContent.thumbnail}`"
                      :alt="selectedContent.attachments[props.i].caption"
                      style="height: 25px ;"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    /> -->

                            <!-- <video-player
                      :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                      :poster="`${URL}/${selectedContent.thumbnail}`"
                      controls
                      :volume="0.6"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    /> -->
                            <!-- <vue-core-video-player
                      :src="`${URL}/${selectedContent.attachments[props.i].url}`"
                      :controls="true"
                      v-if="selectedContent.attachments[props.i].type === 'video'"
                    ></vue-core-video-player> -->
                          </a>
                          <a v-else-if="item.attachments[props.i].type === 'video'">
                            <video style="height: 25px;">
                              <source
                                :src="`${URL}/${item.attachments[props.i].url}`"
                                type="video/mp4"
                              />
                            </video>
                          </a>
                        </template>
                        <div v-for="itemAttach in item.attachments" :key="itemAttach.id">
                          <div
                            style="display: flex;flex-direction: column; justify-content: center; align-items: center;"
                          >
                            <a-image
                              :src="`${URL}/${itemAttach.url}`"
                              :alt="itemAttach.caption"
                              style="height: 150px; width: 250px;"
                              v-if="itemAttach.type === 'image'"
                            />
                            <video
                              v-else-if="itemAttach.type === 'video'"
                              style="height: 150px; width: 250px;"
                              controls
                            >
                              <source :src="`${URL}/${itemAttach.url}`" type="video/mp4" />
                            </video>
                            <p style="text-align: center;margin-bottom: 0">
                              {{ itemAttach.caption }}
                            </p>
                          </div>
                        </div>
                      </a-carousel>
                      <!-- <a-carousel
                        arrows
                        dots-class="slick-dots slick-thumb"
                        v-if="item.attachments.length"
                      >
                        <template #customPaging="props">
                          <a>
                            <img
                              :src="`${URL}/${item.attachments[props.i].url}`"
                              :alt="item.attachments[props.i].caption"
                              style="height: 25px ;"
                            />
                          </a>
                        </template>
                        <div v-for="itemAtt in item.attachments" :key="itemAtt.id">
                          <div style="display: flex;flex-direction: column;">
                            <a-image
                              :src="`${URL}/${itemAtt.url}`"
                              :alt="itemAtt.caption"
                              style="height: 200px ;"
                            />
                            <p style="text-align: center;margin-bottom: 0">{{ itemAtt.caption }}</p>
                          </div>
                        </div>
                      </a-carousel> -->
                      <div v-else>Empty Contents</div>
                    </a-form-item>
                    <a-form-item label="Comments">
                      <div v-if="item.lastComments.lenght">
                        <div v-for="itemComment in item.lastComments" :key="itemComment._id">
                          <div style="margin-bottom: 15px;">
                            <div style="display: flex; align-items: center; margin-bottom: 5px;">
                              <a-avatar
                                :src="`${URL}/${itemComment.creator.avatar_sm}`"
                                v-if="itemComment.creator.avatar_sm"
                              />
                              <a-avatar
                                :src="`${URL}/${itemComment.creator.avatar}`"
                                v-else-if="itemComment.creator.avatar"
                              />
                              <a-avatar v-else>
                                <template #icon><UserOutlined /></template
                              ></a-avatar>
                              <p
                                style="margin-left: 5px; font-weight: 700; text-align: center;margin-bottom: 0;"
                              >
                                {{ itemComment.creator.username }}
                              </p>
                            </div>
                            <p style="margin-bottom: 0;">
                              {{ itemComment.comment }}
                            </p>
                            <div
                              style="background-color: black; width: 100%; height: 1px;margin-top: 5px;"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div v-else>Empty Comment</div>
                    </a-form-item>
                  </a-card>
                </div>
              </div>
              <div v-else>Empty Long Story</div>
            </a-form-item>
            <a-form-item label="Comments">
              <div v-if="selectedStory.lastComments.length">
                <div v-for="(item, index) in selectedStory.lastComments" :key="index">
                  <div style="margin-bottom: 15px;">
                    <div style="display: flex; align-items: center; margin-bottom: 5px;">
                      <a-avatar
                        :src="`${URL}/${item.creator.avatar_sm}`"
                        v-if="item.creator.avatar_sm"
                      />
                      <a-avatar
                        :src="`${URL}/${item.creator.avatar}`"
                        v-else-if="item.creator.avatar"
                      />
                      <a-avatar v-else>
                        <template #icon><UserOutlined /></template
                      ></a-avatar>
                      <p
                        style="margin-left: 5px; font-weight: 700; text-align: center;margin-bottom: 0;"
                      >
                        {{ item.creator.username }}
                      </p>
                    </div>
                    <p style="margin-bottom: 0;">
                      {{ item.comment }}
                    </p>
                    <div
                      style="background-color: black; width: 100%; height: 1px;margin-top: 5px;"
                    ></div>
                  </div>
                </div>
              </div>
              <div v-else>Empty Comment</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <a-modal
      :visible="modalVisibleVideo"
      :footer="null"
      :width="750"
      @ok="handleOkOrCancel"
      @cancel="handleOkOrCancel"
      class="videoContent"
      :body-style="{ padding: '0' }"
      destroy-on-close
    >
      <div style="display: flex; justify-content: center;align-items: center;width: 100%;">
        <video style="width: 100%;" controls>
          <source :src="`${URL}/${selectedVideoContent.url}`" type="video/mp4" />
        </video>
      </div>
    </a-modal>
    <a-radio-group v-model:value="type" @change="handleChangeType">
      <a-radio-button value="content">Content</a-radio-button>
      <a-radio-button value="story">Story</a-radio-button>
    </a-radio-group>
    <div class="mt-2">
      <a-table
        :columns="columnsContent"
        :data-source="dataSourceContent"
        :loading="loadingContent"
        :pagination="paginationContent"
        bordered
        :row-key="record => record._id"
        v-if="type === 'content'"
        :scroll="{ x: 'max-content' }"
        @change="handleChangeContentTable"
      >
        <template #premium="{ text }">
          <a-tag :color="text === true ? 'purple' : 'green'">{{
            text === true ? 'Premium' : 'Regular'
          }}</a-tag>
        </template>
        <template #visibility="{ record }">
          {{ record.shareTo }}
        </template>
        <!-- <template #no="{index}">
          {{ index + 1 }}
        </template> -->
        <!-- <template #isTrending="{ text }">
          <a-tag v-if="text"
            ><i class="fe fe-check text-success" aria-hidden="true"></i> Position #1</a-tag
          >
          <a-tag v-else> <i class="fe fe-x" aria-hidden="true"></i> Not Trending </a-tag>
        </template> -->
        <!-- <template #expandedRowRender="{ record }">
          <a-table :columns="innerColumns" :data-source="record.attachments" :pagination="false">
            <template #filepath="{ text, record }">
              <img
                v-if="record.type == 'image'"
                :src="record.url"
                :alt="record.caption || record.url"
                style="height: 120px"
                class="img-thumbnail"
              />
              <span v-else>{{ record.url }}</span>
            </template>
          </a-table>
        </template> -->
        <template #price="{record}" v-if="type === 'content'"> {{ record.price }} Coins </template>
        <template #content="{record}">
          <a-image
            :src="`${URL}/${record.attachments[0].url}`"
            :alt="record.attachments[0].caption"
            style="height: 100px; cursor: pointer;"
            class="img-thumbnail"
            v-if="record.attachments[0].type === 'image'"
          />
          <a
            v-else-if="record.attachments[0].type === 'video'"
            @click="showModalVideo(record.attachments[0])"
          >
            <video style="height: 100px;">
              <source :src="`${URL}/${record.attachments[0].url}`" type="video/mp4" />
            </video>
          </a>
          <a-tag v-else>No Data</a-tag>
        </template>
        <template #action="{record}">
          <!-- <router-link
            class="text-info kit__utils__link"
            :to="{
              name: 'Detail Post Akun',
              params: {
                id: $route.params.id,
                post: record._id,
                typePost: 'asd',
                selectedContent: { ...record },
              },
            }"
            ><a-button>Detail</a-button></router-link
          > -->
          <a-button @click="() => handleClickDetail(record)">Detail</a-button>
        </template>
      </a-table>
      <a-table
        :columns="columnsStory"
        :data-source="dataSourceStory"
        :loading="loadingStory"
        :pagination="paginationStory"
        bordered
        :row-key="record => record._id"
        v-if="type === 'story'"
        :scroll="{ x: 'max-content' }"
        @change="handleChangeStoryTable"
      >
        <template #premium="{ text }">
          <a-tag :color="text === true ? 'purple' : 'green'">{{
            text === true ? 'Premium' : 'Regular'
          }}</a-tag>
        </template>
        <template #visibility="{ record }">
          {{ record.shareTo }}
        </template>
        <!-- <template #no="{index}">
          {{ index + 1 }}
        </template> -->
        <!-- <template #isTrending="{ text }">
          <a-tag v-if="text"
            ><i class="fe fe-check text-success" aria-hidden="true"></i> Position #1</a-tag
          >
          <a-tag v-else> <i class="fe fe-x" aria-hidden="true"></i> Not Trending </a-tag>
        </template> -->
        <!-- <template #expandedRowRender="{ record }">
          <a-table :columns="innerColumns" :data-source="record.attachments" :pagination="false">
            <template #filepath="{ text, record }">
              <img
                v-if="record.type == 'image'"
                :src="record.url"
                :alt="record.caption || record.url"
                style="height: 120px"
                class="img-thumbnail"
              />
              <span v-else>{{ record.url }}</span>
            </template>
          </a-table>
        </template> -->
        <template #price="{record}" v-if="type === 'content'"> {{ record.price }} Coins </template>
        <template #content="{record}">
          <a-image
            :src="`${URL}/${record.attachments[0].url}`"
            :alt="record.attachments[0].caption"
            style="height: 100px; cursor: pointer;"
            class="img-thumbnail"
            v-if="record.attachments[0]?.type === 'image'"
          />
          <a
            v-else-if="record.attachments[0]?.type === 'video'"
            @click="showModalVideo(record.attachments[0])"
          >
            <video style="height: 100px;">
              <source :src="`${URL}/${record.attachments[0].url}`" type="video/mp4" />
            </video>
          </a>
          <a-tag v-else>No Data</a-tag>
        </template>
        <template #action="{record}">
          <!-- <a-button @click="() => showModal(record)">Detail</a-button> -->
          <!-- <router-link
            class="text-info kit__utils__link"
            :to="{
              name: 'Detail Post Akun',
              params: {
                id: $route.params.id,
                post: record._id,
                typePost: 'asd',
                selectedStory: record,
              },
            }"
            ><a-button>Detail</a-button></router-link
          > -->
          <a-button @click="() => handleClickDetail(record)">Detail</a-button>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import priceFormat from '@/helpers/rupiahPrice'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { usePagination } from 'vue-request'
import { UserOutlined } from '@ant-design/icons-vue'
import { cloneDeep } from 'lodash'
const URL = process.env.VUE_APP_BACKEND_URL

let columnsContent = [
  // {
  //   title: 'Trending',
  //   dataIndex: 'isTrending',
  //   key: 'isTrending',
  //   slots: { customRender: 'isTrending' },
  // },
  // {
  //   title: 'No',
  //   dataIndex: 'no',
  //   key: 'no',
  //   slots: { customRender: 'no' },
  // },
  // {
  //   title: 'Banner',
  //   dataIndex: 'banner',
  //   key: 'banner',
  //   slots: { customRender: 'banner' },
  // },
  {
    title: 'Sentence',
    dataIndex: 'sentence',
    key: 'sentence',
    slots: { customRender: 'sentence' },
    width: 250,
  },
  {
    title: 'Visibility',
    dataIndex: 'visibility',
    key: 'visibility',
    slots: { customRender: 'visibility' },
  },
  {
    title: 'Premium',
    dataIndex: 'isPremium',
    key: 'isPremium',
    slots: { customRender: 'premium' },
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    slots: { customRender: 'price' },
  },
  // {
  //   title: 'Category',
  //   dataIndex: 'category',
  //   key: 'category',
  //   slots: { customRender: 'category' },
  // },
  {
    title: 'Content',
    dataIndex: 'content',
    key: 'content',
    slots: { customRender: 'content' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    slots: { customRender: 'action' },
  },
]
let columnsStory = [
  // {
  //   title: 'Trending',
  //   dataIndex: 'isTrending',
  //   key: 'isTrending',
  //   slots: { customRender: 'isTrending' },
  // },
  // {
  //   title: 'No',
  //   dataIndex: 'no',
  //   key: 'no',
  //   slots: { customRender: 'no' },
  // },
  // {
  //   title: 'Banner',
  //   dataIndex: 'banner',
  //   key: 'banner',
  //   slots: { customRender: 'banner' },
  // },
  {
    title: 'Sentence',
    dataIndex: 'sentence',
    key: 'sentence',
    slots: { customRender: 'sentence' },
    width: 250,
  },
  {
    title: 'Visibility',
    dataIndex: 'visibility',
    key: 'visibility',
    slots: { customRender: 'visibility' },
  },
  {
    title: 'Premium',
    dataIndex: 'isPremium',
    key: 'isPremium',
    slots: { customRender: 'premium' },
  },
  // {
  //   title: 'Coin Amount',
  //   dataIndex: 'price',
  //   key: 'price',
  //   slots: { customRender: 'price' },
  // },
  // {
  //   title: 'Category',
  //   dataIndex: 'category',
  //   key: 'category',
  //   slots: { customRender: 'category' },
  // },
  {
    title: 'Content',
    dataIndex: 'content',
    key: 'content',
    slots: { customRender: 'content' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    slots: { customRender: 'action' },
  },
]
const innerColumns = [
  {
    title: 'Content Name',
    dataIndex: 'filename',
    key: 'filename',
    slots: { customRender: 'filename' },
  },
  {
    title: 'Content Type',
    dataIndex: 'filetype',
    key: 'filetype',
    slots: { customRender: 'filetype' },
  },
  {
    title: 'Content Path',
    dataIndex: 'filepath',
    key: 'filepath',
    slots: { customRender: 'filepath' },
  },
]

export default {
  components: { UserOutlined },
  props: {
    wishlists: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  setup(props) {
    // const keyedWishists = computed(() => {
    //   return props.wishlists.map(wishlist => {
    //     return {
    //       ...wishlist,
    //       key: wishlist._id,
    //     }
    //   }) || []
    // })
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    let dataContent = ref([])
    let dataStory = ref([])
    let type = ref('content')
    let modalVisibleContent = ref(false)
    let modalVisibleStory = ref(false)
    let modalVisibleVideo = ref(false)
    const selectedContent = ref({})
    const selectedStory = ref({})
    const showModal = record => {
      if (type.value === 'content') {
        selectedContent.value = record
        modalVisibleContent.value = true
      } else if (type.value === 'story') {
        selectedStory.value = record
        modalVisibleStory.value = true
      }
    }
    let selectedVideoContent = ref({})
    const showModalVideo = record => {
      selectedVideoContent.value = record
      modalVisibleVideo.value = true
    }
    const handleOkOrCancel = () => {
      modalVisibleContent.value = false
      modalVisibleStory.value = false
      modalVisibleVideo.value = false
    }
    onMounted(() => {
      runContent({
        results: 0,
        page: 0,
        sortField: null,
        sortOrder: null,
      })
    })

    const fetchPost = async params => {
      // const rawData = await store.dispatch('category/FETCH_CATEGORY', search)
      if (type.value === 'content') {
        const data = await store.dispatch('akun/ADMIN_FETCH_USER_CONTENT', {
          _id: route.params.id,
          ...params,
        })
        // dataContent.value = store.state.akun.contents
        return data
      } else if (type.value === 'story') {
        const data = await store.dispatch('akun/ADMIN_FETCH_USER_STORY', {
          _id: route.params.id,
          ...params,
        })
        // dataStory.value = store.state.akun.stories
        return data
      }

      // console.log(account.posts)
      // if (route.params.post) data.value = account.posts.filter(el => el.key == route.params.post)
      // else data.value = account.posts
    }
    const {
      data: dataSourceContent,
      run: runContent,
      loading: loadingContent,
      current: currentContent,
      pageSize: pageSizeContent,
    } = usePagination(fetchPost, {
      formatResult: res => res.data,
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'results',
      },
    })
    const {
      data: dataSourceStory,
      run: runStory,
      loading: loadingStory,
      current: currentStory,
      pageSize: pageSizeStory,
    } = usePagination(fetchPost, {
      formatResult: res => res.data,
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'results',
      },
    })
    const handleChangeContentTable = (pag, filters, sorter) => {
      runContent({
        results: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      })
    }
    const paginationContent = computed(() => ({
      total: dataSourceContent.length,
      current: currentContent.value,
      pageSize: 5,
    }))
    const handleChangeStoryTable = (pag, filters, sorter) => {
      runStory({
        results: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      })
    }
    const paginationStory = computed(() => ({
      total: dataSourceStory.length,
      current: currentStory.value,
      pageSize: 5,
    }))
    const handleChangeType = e => {
      if (e.target.value === 'story') {
        runStory({
          results: 0,
          page: 0,
          sortField: null,
          sortOrder: null,
        })
      } else if (e.target.value === 'content') {
        runContent({
          results: 0,
          page: 0,
          sortField: null,
          sortOrder: null,
        })
      }
    }

    const handleClickDetail = data => {
      if (type.value === 'content') {
        router.push({
          name: 'Detail Post Akun',
          params: {
            id: route.params.id,
            post: data._id,
            typePost: type.value,
            // typePost: 'hallo',
            // selectedContent: cloneDeep(filteredData),
          },
        })
      } else if (type.value === 'story') {
        router.push({
          name: 'Detail Post Akun',
          params: {
            id: route.params.id,
            post: data._id,
            typePost: type.value,
          },
        })
      }
    }

    return {
      columnsContent,
      columnsStory,
      priceFormat,
      dataContent,
      dataStory,
      dataSourceContent,
      dataSourceStory,
      loadingStory,
      loadingContent,
      paginationContent,
      paginationStory,
      handleChangeStoryTable,
      handleChangeContentTable,
      innerColumns,
      type,
      fetchPost,
      URL,
      modalVisibleContent,
      modalVisibleStory,
      modalVisibleVideo,
      showModal,
      handleOkOrCancel,
      selectedContent,
      selectedStory,
      selectedVideoContent,
      handleChangeType,
      showModalVideo,
      handleClickDetail,
    }
  },
}
</script>

<style lang="scss">
.videoContent {
  .ant-modal-content {
    background-color: rgba(20, 19, 34, 0.0001) !important;
    box-shadow: none;
  }
}
</style>
<style scoped>
.ant-carousel :deep(.slick-dots) {
  position: relative;
  height: auto;
}
.ant-carousel :deep(.slick-slide img) {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 80%;
}
.ant-carousel :deep(.slick-arrow) {
  display: none !important;
}
.ant-carousel :deep(.slick-thumb) {
  bottom: 0px;
}
.ant-carousel :deep(.slick-thumb li) {
  margin: 5px 10px;
}
.ant-carousel :deep(.slick-thumb li img) {
  filter: grayscale(100%);
  display: block;
}
.ant-carousel :deep .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
</style>
